<template>
  <div class="tea">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>茶产业</h3>
      <p>Tea industry</p>
    </div>
    <!-- head -->
    <!-- content -->
    <Content :data="content" />
    <!-- content -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [
        {
          state: false,
          // imgSrc: require("@/assets/img/秀圆果防伪溯源系统（logo）.mp4"),
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/秀圆果防伪溯源系统（logo）.mp4",
          title: "溯享平台——RFID防伪溯源系统",
          text: "RFID标签是一种电子防伪标签，主要用于识别产品的真伪性，保护企业自主品牌商品，防止假冒防伪商品在市场上流通。RFID防伪溯源系统是基于物联网技术，将电子防伪标签附与产品， 消费者使用手机“碰一碰”对RFID标签进行识别， 检验真伪信息，读取商品相关信息。通过云平台对标签数据进行解密并判断商品真伪。 借助手机查询方式，建立商家与消费者信息互动通道，查询画面除了展示防伪认证信息外，同时也提供品牌宣传、优惠活动、商城引流、会员中心等途径，助力企业营销推广。",
          careArr: ["品牌保护：芯片级的防伪，安全可信", "品牌赋能：高端产品的专属身份标识", "助力渠道：升级防窜货智能化管理模式", "数字营销：基于溯源查询连接商家与客户"],
          iconArr: [
            {
              icon: require("@/assets/img/icon1.png"),
              href: "https://baijiahao.baidu.com/s?id=1651166263130218479&wfr=spider&for=pc",
            },
            {
              icon: require("@/assets/img/icon2.png"),
              href: "https://www.bilibili.com/video/av243469756/",
            },
            {
              icon: require("@/assets/img/icon3.png"),
              href: "http://news.yunnan.cn/system/2019/08/29/030366952.shtml",
            },
            {
              icon: require("@/assets/img/icon7.png"),
              href: "https://www.puercn.com/news/29532/",
            },
            { icon: require("@/assets/img/icon8.png") },
            { icon: require("@/assets/img/icon9.png") },
            { icon: require("@/assets/img/icon10.png") },
            { icon: require("@/assets/img/icon11.png") },
            { icon: require("@/assets/img/icon14.png") },
            { icon: require("@/assets/img/icon19.png") },
          ],
        },
        {
          state: true,
          imgSrc: require("@/assets/img/tea5.png"),
          title: "溯享平台——订货系统",
          text: "秀圆果订货系统是为针对茶行业特点，针对供应链协作而研发的一款系统管理软件。该系统基于互联网，实现茶企与经销商、零售门店之间实时高效的订货、发货、收货管理，清晰明了的收付款对帐管理、全可靠的在线支付管理，以及方便快捷的订单消息通知等全方位、高效的订货流程管理，全面提升企业管理竞争力。",
          careArr: ["针对茶行业供应链特点而研发", "完善的供应商管理体系和灵活的分级权限", "满足日常订货、订货会等多种订货方式", "支持现金钱包、订货会钱包、补偿钱包"],
          iconArr: [{ icon: require("@/assets/img/icon2.png") }],
        },
        {
          state: false,
          // imgSrc: require("@/assets/img/一部手机管门店 .mp4"),
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/一部手机管门店 .mp4",
          title: "溯享平台——门店系统",
          text: "秀圆果数字化门店系统，打通门店的前端销售与后台仓库管理，整合线上线下业务场景，“一部手机”为门店日常经营提供数字化管理手段。本系统产品更适用于连锁门店，商品信息与价格基于云平台由总部统一维护，门店使用微信小程序对门店仓库、收银结算、经营数据统一管理，同时可开展微信商城线上销售。",
          careArr: [
            "提供手机微信小程序，无需电脑收银机",
            "线上微信商城、线下实体经营同步管理",
            "进销存一体化管理，业绩图表、员工绩效管理",
            "会员、优惠券、兑换券、销售折扣灵活管理",
          ],
          iconArr: [
            { icon: require("@/assets/img/icon2.png") },
            { icon: require("@/assets/img/icon20.png") },
            { icon: require("@/assets/img/icon16.png") },
            { icon: require("@/assets/img/icon21.png") },
          ],
        },
        {
          state: true,
          imgSrc: require("@/assets/img/tea4.png"),
          title: "溯享平台——渠道管理系统",
          text: "秀圆果防窜货系统为企业定制二维码/RFID追溯方案，通过对二维码/RFID标签一对一信息监控记录，实现对每件产品精准控制，可精确追踪到每个产品的流向以及代理商进货、发货的行为，可监视产品的所有流通信息。想要防止各级代理商窜货，为品牌产品运用防窜货系统，不仅可以阻止经销商窜货，还可以统一管理分销商。",
          careArr: [
            "基于已使用的RFID标签、包装二维码标签进行系统扩展 ",
            "防窜货系统与秀圆果溯享平台其它系统实现互通",
            "提供多种模式的防窜货管理机制，满足企业多种管理诉求",
            "支持企业制定开发，可对接企业已有信息系统",
          ],
          iconArr: [{ icon: require("@/assets/img/icon2.png") }, { icon: require("@/assets/img/icon3.png") }],
        },
        {
          state: false,
          title: "溯享平台——原料茶流通系统",
          // imgSrc: require("@/assets/img/秀圆果原料茶交易系统（logo）.mp4"),
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/秀圆果原料茶交易系统（logo）.mp4",
          text: "原料茶流通管理系统，是为解决茶企在原料茶（散茶）批发销售过程中被不法经销商“以次充好”、“违约超卖”的情况，通过原料茶的“重量”作为销售记录单位，在销售过程中通过对“重量”的记录和扣减来达到流通管控的目的。",
          careArr: [
            "以RFID为原料茶产品“重量”标识，确保唯一性，杜绝造假",
            "在销售过程中读取RFID标签，认领购买“重量”，生成相应电子凭证",
            "消费者电子凭证可关联权益，比如获得积分、商城优惠券等",
            "为茶企提供完整的销售记录和追溯记录",
          ],
          iconArr: [{ icon: require("@/assets/img/icon20.png") }],
        },
        {
          state: true,
          imgSrc: require("@/assets/img/tea6.png"),
          title: "溯源平台——数字茶仓系统",
          text: "秀圆果仓库管理系统服务于中小型仓库，为企业仓储部门解决库存不明晰、寻找货物不便捷等难题，将仓储管理以传统纸质和电子文档管理方式转变为系统化、智能化管理方式，在出入库业务、仓库调拨、批次管理、库位管理、库存盘点、即时库存等业务场景中开展全数字化应用。为企业提供低成本，高效率的产品及解决方案。",
          careArr: [
            "基于RFID、二维码识别快速精准出入库",
            "可视化库位-货物管理，快捷寻货，智能化管理",
            "温湿度全程记录，可关联产品追溯记录",
            "仓库系统与秀圆果溯享平台其它系统实现互通",
          ],
          iconArr: [{ icon: require("@/assets/img/icon2.png") }],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .tea {
    position: relative;
    height: 100%;
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../../assets/img/tea-banner1.jpg) no-repeat;
      background-size: 100% 100%;

      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.45rem;
        margin-left: 8.1rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .tea {
    position: relative;
    height: 100%;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../../assets/img/tea-banner1.jpg) no-repeat;
      background-size: 100% 100%;
      h3 {
        // width: 3rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
        text-align: center;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        text-align: center;
      }
    }
  }
}
</style>
